import React, { useState } from "react";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { Loader } from "@omnigenbiodata/ui";
import MainLayout from "../../../../layout/Main";
import { useAppSelector, useAppDispatch } from "../../../../store";
import {
  updateNotifyNewProductsThunk,
  updateHealthDataOptInThunk,
} from "../../../../store/participant/";
import {
  healthDataOptInSelector,
  notifyNewProductsSelector,
  isBusySelector,
  hasErrorSelector,
  withdrawalOptionSelector,
} from "../../../../store/participant/selectors";
import UpdateCommsForm from "./components/UpdateCommsForm";
import UpdateHealthForm from "./components/UpdateHealthForm";
import WithdrawalOption from "./components/WithdrawalOption";

function PreferencesScene() {
  const { t } = useTranslation("portal");
  const dispatch = useAppDispatch();
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const healthDataOptIn = useAppSelector(healthDataOptInSelector);
  const notifyNewProducts = useAppSelector(notifyNewProductsSelector);
  const withdrawalOption = useAppSelector(withdrawalOptionSelector);
  const isBusy = useAppSelector(isBusySelector);
  const hasError = useAppSelector(hasErrorSelector);

  const handleUpdateHealthSubmit = (values: any) => {
    setHasSubmitted(true);
    dispatch(updateHealthDataOptInThunk(values.healthDataOptIn));
  };

  const handleUpdateCommsSubmit = (values: any) => {
    setHasSubmitted(true);
    dispatch(updateNotifyNewProductsThunk(values.notifyNewProducts));
  };

  return (
    <>
      <MainLayout>
        <Typography variant="h4" component="h1" align="center" paragraph>
          {t("preferences.title")}
        </Typography>

        {hasSubmitted && !isBusy && hasError && (
          <Box mb={2}>
            <Alert severity="error">{t("preferences.messages.error")}</Alert>
          </Box>
        )}

        <UpdateHealthForm
          onSubmit={handleUpdateHealthSubmit}
          initialValues={{
            healthDataOptIn: healthDataOptIn || false,
          }}
        />
        <UpdateCommsForm
          onSubmit={handleUpdateCommsSubmit}
          initialValues={{
            notifyNewProducts: notifyNewProducts || false,
          }}
        />
        <WithdrawalOption selectedOption={withdrawalOption} />
      </MainLayout>
      <Loader isVisible={isBusy} label="Saving" />
    </>
  );
}

export default PreferencesScene;
