import {
  EMAIL,
  INVALID_EMAIL,
  OTP,
  OTP_SETUP_CODE,
  VALID_PASSWORD_NEW,
} from "../core/testing/strings.testing";

import {
  getConsentFormLinkQuery,
  getMyProfileQuery,
  getVisitsQuery,
  getEhrStatusQuery,
} from "../core/graphql/queries";
import {
  MOCK_ACCEPT_LEGAL_NOTICES_RESPONSE,
  MOCK_CONSENT_LINK_RESPONSE,
  MOCK_CONFIRM_LOGIN_RESPONSE,
  MOCK_PROFILE_RESPONSE,
  MOCK_UPDATE_HEALTH_DATA_OPTIN_RESPONSE,
  MOCK_UPDATE_NOTIFY_NEW_PRODUCTS_RESPONSE,
  MOCK_UPDATE_PROFILE_RESPONSE,
  MOCK_WITHDRAW_PARTICIPANT_RESPONSE,
  MOCK_VISITS_RESPONSE,
  MOCK_EHR_STATUS_RESPONSE,
} from "../core/testing/store.testing";
import {
  updateHealthDataOptInMutation,
  updateMyProfileMutation,
  updateNotifyNewProductsMutation,
  acceptLegalNoticesMutation,
  withdrawStudyParticipantMutation,
  confirmLoginMutation,
} from "../core/graphql/mutations";

const CURRENT_USER_EXT = {
  username: "MOCK_USERNAME",
  email: "EMAIL",
  accessToken: "ACCESS_TOKEN",
  name: "FULL_NAME",
  phone: "PHONE",
  phoneVerified: false,
  attributes: {
    sub: "SUB",
    email: "EMAIL",
  },
  challengeName: "CHALLENGE_NAME",
  signInUserSession: {
    accessToken: { jwtToken: "JWT" },
  },
};

const MOCK_FORGOT_PASSWORD_RESPONSE = {
  CodeDeliveryDetails: {
    AttributeName: "Email",
    DeliveryMedium: "EMAIL",
    Destination: "m**@o**uk",
  },
};

const MOCK_CURRENT_CREDENTIALS_RESPONSE = {
  accessKeyId: "ACCESSKEY",
  authenticated: true,
  expiration: {},
  identityId: "eu-west-2:1234-5678-9012-3456-8901234",
  secretAccessKey: "SECRETACCESSKEY",
  sessionToken: "SESSIONTOKEN",
};

const ERR_CODE_MISAMTCH = {
  code: "CodeMismatchException",
  name: "CodeMismatchException",
  message: "Invalid verification code provided, please try again.",
};
const ERR_NOT_AUTHORIZED = {
  code: "NotAuthorizedException",
  name: "NotAuthorizedException",
  message: "Incorrect username or password.",
};
const ERR_NOT_FOUND = {
  code: "UserNotFoundException",
  name: "UserNotFoundException",
  message: "User does not exist.",
};
const ERR_INVALID_PASSWORD = {
  code: "Invalidpassword",
  name: "Invalidpassword",
  message: "Invalidpassword",
};

export const Auth = {
  currentAuthenticatedUser: () =>
    new Promise((resolve) => {
      return resolve(CURRENT_USER_EXT);
    }),
  setPreferredMFA: () => {},
  currentSession: () => () => Promise.resolve(),
  signUp: () => () => Promise.resolve(),

  completeNewPassword: (currentUser: any, password: string) =>
    new Promise((resolve, reject) => {
      if (password === VALID_PASSWORD_NEW) {
        return resolve(CURRENT_USER_EXT);
      }
      return reject(ERR_INVALID_PASSWORD);
    }),
  setupTOTP: (currentUser: any) =>
    new Promise((resolve, reject) => {
      return resolve(OTP_SETUP_CODE);
    }),
  confirmSignIn: (currentUser: any, code: string) =>
    new Promise((resolve, reject) => {
      const confirmedUser = {
        userConfirmed: true,
        username: currentUser.username,
        user: currentUser,
      };

      if (code === OTP) {
        return resolve(confirmedUser);
      }

      return reject(ERR_CODE_MISAMTCH);
    }),
  verifyTotpToken: (currentUser: any, code: string) =>
    new Promise((resolve, reject) => {
      const confirmedUser = {
        userConfirmed: true,
        username: currentUser.username,
        user: currentUser,
      };

      if (code === OTP) {
        return resolve(confirmedUser);
      }

      return reject(ERR_CODE_MISAMTCH);
    }),
  signIn: (email: string, password: string) => {
    return new Promise((resolve, reject) => {
      if (email === EMAIL) {
        return resolve(CURRENT_USER_EXT);
      } else if (email === INVALID_EMAIL) {
        return reject(ERR_NOT_AUTHORIZED);
      }

      return reject(ERR_NOT_FOUND);
    });
  },
  currentCredentials: () => {
    return new Promise((resolve) => {
      return resolve(MOCK_CURRENT_CREDENTIALS_RESPONSE);
    });
  },
  forgotPassword: () => {
    return new Promise((resolve) => {
      return resolve(MOCK_FORGOT_PASSWORD_RESPONSE);
    });
  },
  forgotPasswordSubmit: () => {
    return new Promise((resolve, reject) => {
      return resolve("SUCCESS");
    });
  },
};

export const API = {
  graphql: (request: any) =>
    new Promise((resolve, reject) => {
      if (request.query === getMyProfileQuery) {
        return resolve(MOCK_PROFILE_RESPONSE);
      } else if (request.query === getConsentFormLinkQuery) {
        return resolve(MOCK_CONSENT_LINK_RESPONSE);
      } else if (request.query === updateMyProfileMutation) {
        return resolve(MOCK_UPDATE_PROFILE_RESPONSE);
      } else if (request.query === updateHealthDataOptInMutation) {
        return resolve(MOCK_UPDATE_HEALTH_DATA_OPTIN_RESPONSE);
      } else if (request.query === updateNotifyNewProductsMutation) {
        return resolve(MOCK_UPDATE_NOTIFY_NEW_PRODUCTS_RESPONSE);
      } else if (request.query === acceptLegalNoticesMutation) {
        return resolve(MOCK_ACCEPT_LEGAL_NOTICES_RESPONSE);
      } else if (request.query === withdrawStudyParticipantMutation) {
        return resolve(MOCK_WITHDRAW_PARTICIPANT_RESPONSE);
      } else if (request.query === confirmLoginMutation) {
        return resolve(MOCK_CONFIRM_LOGIN_RESPONSE);
      } else if (request.query === getVisitsQuery) {
        return resolve(MOCK_VISITS_RESPONSE);
      } else if (request.query === getEhrStatusQuery) {
        return resolve(MOCK_EHR_STATUS_RESPONSE);
      } else {
        resolve({ data: request.query });
      }
    }),
};
