import { GraphQLResponse } from "./participant.types";

/* Health Data */

export enum RegType {
  IN = "IN",
  OUT = "OUT",
  ER = "ER",
}

export interface Visits {
  visitLocation: string;
  startDate: string;
  endDate: string;
  department: string;
  registrationType: RegType;
  PK: string;
}

export interface EhrStatus {
  dataHasArrived: boolean;
  lastProcessedTime?: string;
  exportedDate?: string;
}

export interface GetVisitsResponse extends GraphQLResponse {
  data: { getEhrVisits: Visits[] };
}

export interface CheckEhrStatusResponse extends GraphQLResponse {
  data: { getEhrStatus: EhrStatus };
}
