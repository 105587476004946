import i18next from "i18next";
import portal_zu from "../../../translations/zu/portal.json";
import portal_en from "../../../translations/en/portal.json";

import pis_zu_v3_0 from "../../../translations/zu/pis/v3.0.json";
import pis_en_v3_0 from "../../../translations/en/pis/v3.0.json";
import pis_zu_v3_1 from "../../../translations/zu/pis/v3.1.json";
import pis_en_v3_1 from "../../../translations/en/pis/v3.1.json";
import pis_zu_v3_2 from "../../../translations/zu/pis/v3.2.json";
import pis_en_v3_2 from "../../../translations/en/pis/v3.2.json";
import pis_zu_v4_2 from "../../../translations/zu/pis/v4.2.json";
import pis_en_v4_2 from "../../../translations/en/pis/v4.2.json";
import pis_zu_v5_2 from "../../../translations/zu/pis/v5.2.json";
import pis_en_v5_2 from "../../../translations/en/pis/v5.2.json";
import video_zu from "../../../translations/zu/video.json";
import video_en from "../../../translations/en/video.json";

import terms_zu from "../../../translations/zu/terms.json";
import terms_en from "../../../translations/en/terms.json";

import privacy_zu from "../../../translations/zu/privacy.json";
import privacy_en from "../../../translations/en/privacy.json";

import comms_zu from "../../../translations/zu/comms.json";
import comms_en from "../../../translations/en/comms.json";

import validation_zu from "../../../translations/zu/validation.json";
import validation_en from "../../../translations/en/validation.json";

export const setupI18next = (defaultLanguage = "en") => {
  i18next.init({
    interpolation: { escapeValue: false }, // React already does escaping
    lng: defaultLanguage, // language to use
    resources: {
      en: {
        portal: portal_en,
        pis_v3_0: pis_en_v3_0,
        pis_v3_1: pis_en_v3_1,
        pis_v3_2: pis_en_v3_2,
        pis_v4_2: pis_en_v4_2,
        pis_v5_2: pis_en_v5_2,
        video: video_en,
        terms: terms_en,
        privacy: privacy_en,
        comms: comms_en,
        validation: validation_en,
      },
      zu: {
        portal: portal_zu,
        pis_v3_0: pis_zu_v3_0,
        pis_v3_1: pis_zu_v3_1,
        pis_v3_2: pis_zu_v3_2,
        pis_v4_2: pis_zu_v4_2,
        pis_v5_2: pis_zu_v5_2,
        video: video_zu,
        terms: terms_zu,
        privacy: privacy_zu,
        comms: comms_zu,
        validation: validation_zu,
      },
    },
  });
  return i18next;
};
