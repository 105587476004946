export const PARTNER_LOGOS = [
  {
    img: "/images/kwazulu-natal-province.png",
    alt: "Kwazulu-Natal Province",
  },
  {
    img: "/images/kwazulu-uni-logo.png",
    alt: "University of Kwazulu-Natal",
  },
  { img: "/images/omnigen-logo-2-x.png", alt: "Omnigen" },
];

export const CURRENT_TERMS_VERSION = "v1.0, effective from 01 November 2021";
export const CURRENT_PRIVACY_VERSION = "v2.0, effective from February 2023";

export const MICROSITE_URL = "https://www.omnigenbiodata.co.uk/dmsa";
export const MICROSITE_URL_FRIENDLY = "www.omnigenbiodata.co.uk/dmsa";
export const STUDY_EMAIL = "discovermesa@ukzn.ac.za";
export const DPO_EMAIL = "dpo@omnigenbiodata.co.uk";
export const STUDY_ADDRESS =
  "For Attn: Professor Ayesha Motala, Department of Diabetes and Endocrinology, University of KwaZulu-Natal, 719 Umbilo Road, Room 343, Congella, Durban, 401";
export const STUDY_TEL = "083 4633 156";
export const TERMS_EMAIL = "participant_dmsa@omnigenbiodata.co.uk";
export const SPLASH_TIMEOUT = 2000;

const CONTENT = {
  DPO_EMAIL,
  PARTNER_LOGOS,
  CURRENT_TERMS_VERSION,
  CURRENT_PRIVACY_VERSION,
  MICROSITE_URL,
  MICROSITE_URL_FRIENDLY,
  STUDY_EMAIL,
  STUDY_ADDRESS,
  STUDY_TEL,
  TERMS_EMAIL,
};
export default CONTENT;
