import React from "react";
import ReactDOM from "react-dom/client";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material";
import { theme, MatomoProvider } from "@omnigenbiodata/ui";
import { I18nextProvider } from "react-i18next";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { setupAmplify } from "./core/utils/amplify";
import { setupI18next } from "./core/utils/i18next";
import { Provider } from "react-redux";
import store from "./store";

const config = {
  urlBase: "https://discovermestudy.matomo.cloud",
  siteId: 6,
  configurations: {
    setSecureCookie: process.env.NODE_ENV !== "development",
    enableJSErrorTracking: true,
  },
};

setupAmplify();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <Provider store={store}>
    <CssBaseline />
    <ThemeProvider theme={theme}>
      <I18nextProvider i18n={setupI18next()}>
        <MatomoProvider config={config}>
          <App />
        </MatomoProvider>
      </I18nextProvider>
    </ThemeProvider>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
