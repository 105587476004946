import React from "react";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import MainLayout from "../../../../layout/Main";
import ParticipantVideo from "../../../../components/content/ParticipantVideo";

function VideoScene() {
  const { t } = useTranslation("portal");

  return (
    <MainLayout>
      <Typography variant="h4" component="h1" align="center" paragraph>
        {t("video.title")}
      </Typography>
      <ParticipantVideo />
    </MainLayout>
  );
}

export default VideoScene;
