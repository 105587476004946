import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import {
  CURRENT_TERMS_VERSION,
  CURRENT_PRIVACY_VERSION,
} from "../../core/constants/content.constant";
import { ROUTES } from "../../core/constants/routes.constant";
import {
  termsAcceptedSelector,
  privacyAcceptedSelector,
  profileSelector,
} from "../../store/participant/selectors";
import { useAppSelector } from "../../store";

const PortalScene = () => {
  const profile = useAppSelector(profileSelector);
  const termsAccepted = useAppSelector(termsAcceptedSelector);
  const privacyAccepted = useAppSelector(privacyAcceptedSelector);
  if (
    !profile ||
    termsAccepted !== CURRENT_TERMS_VERSION ||
    privacyAccepted !== CURRENT_PRIVACY_VERSION
  ) {
    return <Navigate to={ROUTES.splash} />;
  }

  return <Outlet />;
};

export default PortalScene;
