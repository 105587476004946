import React from "react";
import { Navigate } from "react-router-dom";
import Login from "./scenes/Login";
import ConfirmUser from "./scenes/ConfirmUser";
import Passcode from "./scenes/Passcode";

import ROUTES from "../../core/constants/routes.constant";
import { useAuth } from "@omnigenbiodata/ui";
import { AUTH_CHALLENGES } from "../../core/constants/aws.constant";

function AuthScene() {
  const { challengeName, isAuthenticated, authChallengeParams } = useAuth();

  if (isAuthenticated) {
    const NavigateTarget = window.localStorage.getItem("authNavigate");
    window.localStorage.removeItem("authNavigate");
    return <Navigate to={NavigateTarget || ROUTES.root} />;
  }

  if (
    challengeName === AUTH_CHALLENGES.CUSTOM_CHALLENGE &&
    (authChallengeParams.email || authChallengeParams.phone_number)
  ) {
    return <Passcode />;
  }

  if (
    challengeName === AUTH_CHALLENGES.CUSTOM_CHALLENGE &&
    !(authChallengeParams.email || authChallengeParams.phone_number)
  ) {
    return <ConfirmUser />;
  }
  return <Login />;
}

export default AuthScene;
