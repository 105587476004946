import React from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import ReactMarkdown from "react-markdown";

interface ParticipantInfoProps {
  address?: string | null;
  email?: string | null;
  telephone?: string | null;
  web?: string | null;
  version?: string;
}

const getTranslation = (version?: string) => {
  if (!version)
    return { file: "pis_v5_2", PIS_VERSION: "v5.2", PIS_DATE: "06 April 2023" };
  const num = version.match(/(\d+.\d+|\d+)/) || [0];
  if (num[0] >= "5") {
    return { file: "pis_v5_2", PIS_VERSION: "v5.2", PIS_DATE: "06 April 2023" };
  } else if (num[0] >= "4") {
    return {
      file: "pis_v4_2",
      PIS_VERSION: "v4.2",
      PIS_DATE: "07 October 2022",
    };
  } else if (version === "v3.2") {
    return {
      file: "pis_v3_2",
      PIS_VERSION: "v3.2",
      PIS_DATE: "08 FEB 2022",
    };
  } else if (version === "v3.1") {
    return {
      file: "pis_v3_1",
      PIS_VERSION: "v3.1",
      PIS_DATE: "10 JAN 2022",
    };
  } else {
    return {
      file: "pis_v3_0",
      PIS_VERSION: "v3.0",
      PIS_DATE: "10 MAY 2020",
    };
  }
};

function ParticipantInfo({
  address = null,
  email = null,
  telephone = null,
  web = null,
  version,
}: ParticipantInfoProps) {
  const { file, PIS_VERSION, PIS_DATE } = getTranslation(version);
  const { t } = useTranslation(file);
  return (
    <Paper elevation={5}>
      <Box p={3}>
        <Typography paragraph variant="h5" align="left" component="h2">
          <strong>{t("title")}</strong>
        </Typography>
        <Typography paragraph variant="h6" align="left" component="h3">
          {t("heading1")}
        </Typography>
        <Typography variant="body1" align="left" component="div">
          <ReactMarkdown>{t("intro")}</ReactMarkdown>
        </Typography>
        <div>
          <Typography paragraph variant="body1" align="left" component="h4">
            <strong>1. {t("section1.title")}</strong>
          </Typography>
          <Typography variant="body1" align="left" component="div">
            <ReactMarkdown>{t("section1.body")}</ReactMarkdown>
          </Typography>
        </div>
        <div>
          <Typography paragraph variant="body1" align="left" component="h4">
            <strong>2. {t("section2.title")}</strong>
          </Typography>
          <Typography variant="body1" align="left" component="div">
            <ReactMarkdown>{t("section2.body")}</ReactMarkdown>
          </Typography>
        </div>
        <div>
          <Typography paragraph variant="body1" align="left" component="h4">
            <strong>3. {t("section3.title")}</strong>
          </Typography>
          <Typography variant="body1" align="left" component="div">
            <ReactMarkdown>{t("section3.body")}</ReactMarkdown>
          </Typography>
        </div>
        <div>
          <Typography paragraph variant="body1" align="left" component="h4">
            <strong>4. {t("section4.title")}</strong>
          </Typography>
          <Typography variant="body1" align="left" component="div">
            <ReactMarkdown>{t("section4.body")}</ReactMarkdown>
          </Typography>
        </div>
        <div>
          <Typography paragraph variant="body1" align="left" component="h4">
            <strong>5. {t("section5.title")}</strong>
          </Typography>
          <Typography variant="body1" align="left" component="div">
            <ReactMarkdown>{t("section5.body")}</ReactMarkdown>
          </Typography>
        </div>
        <div>
          <Typography paragraph variant="body1" align="left" component="h4">
            <strong>6. {t("section6.title")}</strong>
          </Typography>
          <Typography variant="body1" align="left" component="div">
            <ReactMarkdown>{t("section6.body")}</ReactMarkdown>
          </Typography>
        </div>
        <div>
          <Typography paragraph variant="body1" align="left" component="h4">
            <strong>7. {t("section7.title")}</strong>
          </Typography>
          <Typography variant="body1" align="left" component="div">
            <ReactMarkdown>{t("section7.body")}</ReactMarkdown>
          </Typography>
        </div>
        <div>
          <Typography paragraph variant="body1" align="left" component="h4">
            <strong>8. {t("section8.title")}</strong>
          </Typography>
          <Typography variant="body1" align="left" component="div">
            <ReactMarkdown>{t("section8.body")}</ReactMarkdown>
          </Typography>
        </div>
        <div>
          <Typography paragraph variant="body1" align="left" component="h4">
            <strong>9. {t("section9.title")}</strong>
          </Typography>
          <Typography variant="body1" align="left" component="div">
            <ReactMarkdown>{t("section9.body")}</ReactMarkdown>
          </Typography>
        </div>
        <div>
          <Typography paragraph variant="body1" align="left" component="h4">
            <strong>10. {t("section10.title")}</strong>
          </Typography>
          <Typography variant="body1" align="left" component="div">
            <ReactMarkdown>{t("section10.body")}</ReactMarkdown>
          </Typography>
        </div>
        <div>
          <Typography paragraph variant="body1" align="left" component="h4">
            <strong>11. {t("section11.title")}</strong>
          </Typography>
          <Typography variant="body1" align="left" component="div">
            <ReactMarkdown>{t("section11.body")}</ReactMarkdown>
          </Typography>
        </div>
        <div>
          <Typography paragraph variant="body1" align="left" component="h4">
            <strong>12. {t("section12.title")}</strong>
          </Typography>
          <Typography variant="body1" align="left" component="div">
            <ReactMarkdown>{t("section12.body")}</ReactMarkdown>
          </Typography>
        </div>
        <div>
          <Typography paragraph variant="body1" align="left" component="h4">
            <strong>13. {t("section13.title")}</strong>
          </Typography>
          <Typography variant="body1" align="left" component="div">
            <ReactMarkdown>{t("section13.body")}</ReactMarkdown>
          </Typography>
        </div>
        <div>
          <Typography paragraph variant="body1" align="left" component="h4">
            <strong>14. {t("section14.title")}</strong>
          </Typography>
          <Typography variant="body1" align="left" component="div">
            <ReactMarkdown>{t("section14.body")}</ReactMarkdown>
          </Typography>
        </div>
        <div>
          <Typography paragraph variant="body1" align="left" component="h4">
            <strong>15. {t("section15.title")}</strong>
          </Typography>
          <Typography variant="body1" align="left" component="div">
            <ReactMarkdown>{t("section15.body")}</ReactMarkdown>
          </Typography>
        </div>
        <div>
          <Typography paragraph variant="body1" align="left" component="h4">
            <strong>16. {t("section16.title")}</strong>
          </Typography>
          <Typography variant="body1" align="left" component="div">
            <ReactMarkdown>{t("section16.body")}</ReactMarkdown>
          </Typography>
        </div>
        <div>
          <Typography paragraph variant="body1" align="left" component="h4">
            <strong>17. {t("section17.title")}</strong>
          </Typography>
          <Typography variant="body1" align="left" component="div">
            <ReactMarkdown>{t("section17.body")}</ReactMarkdown>
          </Typography>
        </div>
        <div>
          <Typography paragraph variant="body1" align="left" component="h4">
            <strong>18. {t("section18.title")}</strong>
          </Typography>
          <Typography variant="body1" align="left" component="div">
            <ReactMarkdown>{t("section18.body")}</ReactMarkdown>
          </Typography>
        </div>
        <div>
          <Typography paragraph variant="body1" align="left" component="h4">
            <strong>19. {t("section19.title")}</strong>
          </Typography>
          <Typography variant="body1" align="left" component="div">
            <ReactMarkdown>{t("section19.body")}</ReactMarkdown>
          </Typography>
        </div>
        <div>
          <Typography paragraph variant="body1" align="left" component="h4">
            <strong>20. {t("section20.title")}</strong>
          </Typography>
          <Typography variant="body1" align="left" component="div">
            <ReactMarkdown>{t("section20.body")}</ReactMarkdown>
          </Typography>
        </div>
        <div>
          <Typography paragraph variant="body1" align="left" component="h4">
            <strong>21. {t("section21.title")}</strong>
          </Typography>
          <Typography variant="body1" align="left" component="div">
            <ReactMarkdown>{t("section21.body")}</ReactMarkdown>
          </Typography>
        </div>
        <div>
          <Typography paragraph variant="body1" align="left" component="h4">
            <strong>22. {t("section22.title")}</strong>
          </Typography>
          <Typography variant="body1" align="left" component="div">
            <ReactMarkdown>
              {t("section22.body", {
                address,
                email,
                telephone,
                web,
              })}
            </ReactMarkdown>
          </Typography>
        </div>

        <div>
          <Typography variant="body1" align="left" component="div">
            <ReactMarkdown>
              {`Discover Me South Africa | Participant information sheet
              | ${PIS_VERSION} | ${PIS_DATE}`}
            </ReactMarkdown>
          </Typography>
        </div>
      </Box>
    </Paper>
  );
}

export default ParticipantInfo;
