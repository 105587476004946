import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Scenes from "./scenes";
import {
  AuthProvider,
  ScrollToTop,
  EnvFlag,
  EnvFooter,
  PageTracker,
} from "@omnigenbiodata/ui";
import ENV from "./core/constants/environment.constant";
import ROUTES from "./core/constants/routes.constant";

function App() {
  return (
    <Router>
      <ScrollToTop />
      <AuthProvider authPath={ROUTES.auth} postLogoutPath={ROUTES.authSignOut}>
        <Scenes />
        <EnvFlag env={ENV.ENVIRONMENT as any} version={ENV.VERSION} />
        <EnvFooter env={ENV.ENVIRONMENT as any} version={ENV.FULL_VERSION} />
        <PageTracker />
      </AuthProvider>
    </Router>
  );
}

export default App;
