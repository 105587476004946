import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAppSelector } from "../../store";
import { profileSelector } from "../../store/participant/selectors";
import { ROUTES } from "../../core/constants/routes.constant";

const ConsentScene = () => {
  const profile = useAppSelector(profileSelector);

  if (!profile) {
    return <Navigate to={ROUTES.splash} />;
  }

  return <Outlet />;
};

export default ConsentScene;
