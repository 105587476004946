import React, { useEffect } from "react";
import Hidden from "@mui/material/Hidden";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

import * as yup from "yup";
import Typography from "@mui/material/Typography";
import {
  FormInput,
  Loader,
  FormDob,
  useTrackedFormik,
  useMatomo,
} from "@omnigenbiodata/ui";
import { useTranslation } from "react-i18next";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Markdown from "react-markdown";

import MainLayout from "../../../../layout/Main";
import { useAuth } from "@omnigenbiodata/ui";

function ConfirmUserScene() {
  const { trackPageView } = useMatomo();
  const { submitCustomChallenge2, hasError, isBusy, resetUser } = useAuth();
  const { t } = useTranslation(["portal", "validation"]);

  const validationSchema = yup.object().shape({
    ialch: yup
      .string()
      .required(
        t("ialchRequired", {
          ns: "validation",
        })
      )
      .matches(
        /^[0-9]+$/,
        t("ialchRequired", {
          ns: "validation",
        })
      )
      .min(
        8,
        t("ialchRequired", {
          ns: "validation",
        })
      )
      .max(
        8,
        t("ialchRequired", {
          ns: "validation",
        })
      ),
    dob: yup.string().required(t("dobRequired", { ns: "validation" })),
  });

  const formik = useTrackedFormik(
    {
      initialValues: { ialch: "", dob: "" },
      validationSchema,
      onSubmit: (values) => {
        submitCustomChallenge2(`${values.dob}$${values.ialch}`);
      },
    },
    "confirm-user"
  );

  useEffect(() => {
    if (hasError) {
      resetUser();
    }
  }, [hasError, resetUser]);

  useEffect(() => {
    trackPageView({
      href: `${window.location.href}confirmuser`,
    });
  }, [trackPageView]);

  return (
    <>
      <MainLayout>
        <form onSubmit={formik.handleSubmit}>
          <Box mb={8}>
            <Typography
              variant="h4"
              component="h1"
              align="center"
              gutterBottom
              data-testid="main-heading"
            >
              {t("confirm.title")}
            </Typography>
            <Typography paragraph variant="body1" align="center">
              {t("confirm.intro")}
            </Typography>
          </Box>

          <Box mb={8}>
            <FormInput
              error={formik.errors.ialch}
              name="ialch"
              label={t("confirm.hospitalNum")}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              touched={formik.touched.ialch}
              value={formik.values.ialch}
            />
            <FormDob
              error={formik.errors.dob}
              name="dob"
              label={t("confirm.dob")}
              setValue={formik.setFieldValue}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              touched={formik.touched.dob}
              value={formik.values.dob}
              dayLabel={t("confirm.dayLabel")}
              monthLabel={t("confirm.monthLabel")}
              yearLabel={t("confirm.yearLabel")}
              monthNames={t("confirm.monthNames").split(",")}
            />
          </Box>
          <Box mb={8}>
            <Grid container spacing={2}>
              <Hidden xsDown>
                <Grid item xs={12} md={6} />
              </Hidden>

              <Grid item xs={12} md={6}>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  size="large"
                  type="submit"
                >
                  {t("confirm.button")}
                </Button>
              </Grid>
            </Grid>
          </Box>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography variant="body1">{t("confirm.helpTitle")}</Typography>
            </AccordionSummary>
            <AccordionDetails style={{ flexDirection: "column" }}>
              <Typography variant="body1" component="span" paragraph>
                <Markdown>{t("confirm.helpText")}</Markdown>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography variant="body1">{t("confirm.helpTitle2")}</Typography>
            </AccordionSummary>
            <AccordionDetails style={{ flexDirection: "column" }}>
              <Typography variant="body1" component="span" paragraph>
                <Markdown>{t("confirm.helpText2")}</Markdown>
              </Typography>
            </AccordionDetails>
          </Accordion>
        </form>
      </MainLayout>
      <Loader isVisible={isBusy} label={t("authenticating")} />
    </>
  );
}

export default ConfirmUserScene;
