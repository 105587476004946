import React, { useEffect } from "react";
import { Navigate, useParams } from "react-router-dom";
import ROUTES from "../../core/constants/routes.constant";
import I18N from "../../core/constants/i18n.constant";
import { useTranslation } from "react-i18next";

function LanguageSetScene() {
  const { i18n } = useTranslation("portal");
  const { lang } = useParams<any>();

  useEffect(() => {
    const validLanguage =
      lang && Object.values(I18N.Languages).some((option) => option === lang);
    i18n.changeLanguage(validLanguage ? lang : I18N.Languages.EN);
  }, [i18n, lang]);

  return <Navigate to={ROUTES.auth} />;
}

export default LanguageSetScene;
