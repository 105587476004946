import React, { useEffect } from "react";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Markdown from "react-markdown";
import { FormRadioGroup, Loader, useTrackedFormik } from "@omnigenbiodata/ui";
import MainLayout from "../../../../layout/Main";
import ROUTES from "../../../../core/constants/routes.constant";
import {
  CURRENT_PRIVACY_VERSION,
  CURRENT_TERMS_VERSION,
} from "../../../../core/constants/content.constant";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { acceptLegalNoticesThunk } from "../../../../store/participant";
import {
  privacyAcceptedSelector,
  termsAcceptedSelector,
  isBusySelector,
} from "../../../../store/participant/selectors";

function CommsScene() {
  const { t } = useTranslation(["comms", "portal", "validation"]);
  const dispatch = useAppDispatch();
  const isBusy = useAppSelector(isBusySelector);
  const termsAccepted = useAppSelector(termsAcceptedSelector);
  const privacyAccepted = useAppSelector(privacyAcceptedSelector);
  const navigate = useNavigate();

  const validationSchema = yup.object({
    notifyNewProducts: yup
      .string()
      .required(t("notifyNewProductsRequired", { ns: "validation" })),
    termsAccepted: yup.string().required("Required"),
    privacyAccepted: yup.string().required("Required"),
  });

  useEffect(() => {
    if (
      termsAccepted === CURRENT_TERMS_VERSION &&
      privacyAccepted === CURRENT_PRIVACY_VERSION
    ) {
      navigate(ROUTES.portal);
    }
  }, [navigate, termsAccepted, privacyAccepted]);

  const formik = useTrackedFormik(
    {
      initialValues: {
        notifyNewProducts: "",
        termsAccepted: CURRENT_TERMS_VERSION,
        privacyAccepted: CURRENT_PRIVACY_VERSION,
      },
      validationSchema,
      onSubmit: (values) => {
        dispatch(
          acceptLegalNoticesThunk({
            ...values,
            notifyNewProducts: values.notifyNewProducts === "Y" ? true : false,
          })
        );
      },
    },
    "consent-comms"
  );

  return (
    <>
      <MainLayout>
        <form onSubmit={formik.handleSubmit}>
          <Typography variant="h4" component="h1" align="center" gutterBottom>
            {t("title")}
          </Typography>
          <Box p={4}>
            <Typography variant="body1" component="span">
              <Markdown>{t(`intro`)}</Markdown>
            </Typography>
            <FormRadioGroup
              hideLabel
              error={formik.errors.notifyNewProducts}
              name="notifyNewProducts"
              label={t("title")}
              orText={t("options.or")}
              options={[
                {
                  value: "Y",
                  label: (t("options.option1") as string) || "",
                },
                {
                  value: "N",
                  label: (t("options.option2") as string) || "",
                },
              ]}
              onChange={formik.handleChange}
              touched={formik.touched.notifyNewProducts}
              value={formik.values.notifyNewProducts}
            />
          </Box>

          <Button
            size="xl"
            variant="contained"
            color="primary"
            type="submit"
            fullWidth
          >
            {t("button")}
          </Button>
        </form>
      </MainLayout>
      <Loader isVisible={isBusy} label={t("saving")} />
    </>
  );
}

export default CommsScene;
