import React from "react";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import Markdown from "react-markdown";
import { Link as RouterLink } from "react-router-dom";
import ROUTES from "../../../../../../core/constants/routes.constant";
import { useStyles } from "./component.styles";

interface WithdrawalOptionProps {
  selectedOption?: string;
}

function WithdrawalOption({ selectedOption }: WithdrawalOptionProps) {
  const { t } = useTranslation("portal");
  const { classes } = useStyles();

  return (
    <Box mb={2}>
      <Paper className={classes.paperDanger}>
        <Box p={4}>
          <Typography variant="h6" align="left" gutterBottom>
            <strong>{t("preferences.withdraw.title")}</strong>
          </Typography>

          <Typography variant="body1" paragraph>
            {t("preferences.withdraw.para1")}
          </Typography>
          {selectedOption && (
            <>
              <Typography variant="body1" paragraph>
                {t("preferences.withdraw.previously")}:
              </Typography>
              <Typography variant="body1" component="span" paragraph>
                <Markdown>
                  {t(`withdrawal2.options.${selectedOption}.label`)}
                </Markdown>
              </Typography>
            </>
          )}
          <Button
            variant="contained"
            color="error"
            size="large"
            component={RouterLink}
            data-testid="withdrawal-btn"
            to={ROUTES.portalWithdrawal}
          >
            {t("preferences.withdraw.button")}
          </Button>
        </Box>
      </Paper>
    </Box>
  );
}

export default WithdrawalOption;
