import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()((theme) => ({
  hamburger: {
    display: "inline-block",
    width: "auto",
    top: 16,
    right: 36,
    position: "absolute",
    [theme.breakpoints.up("sm")]: {
      top: 36,
      right: 36,
    },
  },
  drawer: {
    width: 290,
  },
}));
