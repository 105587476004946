import React, { useState } from "react";
import moment from "moment";
import { RegType, Visits } from "../../../../../../core/types/health.types";
import { useTranslation } from "react-i18next";
import { languagePreferenceSelector } from "../../../../../../store/participant/selectors";
import { useAppSelector } from "../../../../../../store";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import EventCard from "../../../../../../components/content/EventCard";
import TablePagination from "@mui/material/TablePagination";
import ReactMarkdown from "react-markdown";
import { Message, useMatomo } from "@omnigenbiodata/ui";

interface VisitsProps {
  title: string;
  visits: Visits[] | null;
}

function VisitsTab({ title, visits }: VisitsProps) {
  const { trackEvent } = useMatomo();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRows] = useState(10);
  const languagePreference = useAppSelector(languagePreferenceSelector);
  const format = languagePreference === "en" ? "DD MMM YYYY" : "DD/MM/YYYY";
  const { t } = useTranslation("portal");
  const formatDate = (start: string, end: string) =>
    start === end
      ? moment(start).format(format)
      : moment(start).format(format) + " - " + moment(end).format(format);

  const handlePagination = (event: any, page: any) => {
    setPage(page);
    trackEvent({ category: "visits", action: `set-page-${page}` });
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRows(parseInt(event.target.value, 10));
    setPage(0);
    trackEvent({
      category: "visits",
      action: `set-rows-${event.target.value}`,
    });
  };
  const getSubtitle = (registrationType: RegType) => {
    switch (registrationType) {
      case RegType.IN:
        return `(${t("health.visits.in")})`;
      case RegType.OUT:
        return `(${t("health.visits.out")})`;
      case RegType.ER:
        return `(${t("health.visits.er")})`;
      default:
        return "";
    }
  };
  return (
    <Box>
      <Typography pb={2} variant="h4">
        {title}
      </Typography>
      <Message severity="info">
        <ReactMarkdown>{t("health.visits.caveat")}</ReactMarkdown>
      </Message>
      {visits &&
        visits
          .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
          .map(
            (
              {
                startDate,
                endDate,
                visitLocation,
                department,
                registrationType,
                PK,
              }: Visits,
              index: number
            ) => (
              <EventCard
                key={index}
                date={formatDate(startDate, endDate)}
                title="Inkosi Albert Luthulu Central Hospital"
                subtitle={`${department} - ${visitLocation} ${getSubtitle(
                  registrationType
                )}`}
              />
            )
          )}
      {visits && (
        <TablePagination
          component="div"
          count={visits.length}
          page={page}
          onPageChange={handlePagination}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </Box>
  );
}

export default VisitsTab;
