import React, { useEffect } from "react";
import Hidden from "@mui/material/Hidden";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import * as yup from "yup";
import Typography from "@mui/material/Typography";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Markdown from "react-markdown";
import {
  FormInput,
  Loader,
  useTrackedFormik,
  useMatomo,
} from "@omnigenbiodata/ui";
import { useTranslation } from "react-i18next";

import MainLayout from "../../../../layout/Main";

import CONTENT from "../../../../core/constants/content.constant";
import { useAuth } from "@omnigenbiodata/ui";

function PasscodeScene() {
  const { trackPageView } = useMatomo();
  const { submitCustomChallenge, hasError, isBusy, resetUser } = useAuth();
  const { t } = useTranslation(["portal", "validation"]);

  const validationSchema = yup.object().shape({
    passcode: yup
      .string()
      .required(t("verificationCodeRequired", { ns: "validation" })),
  });

  const formik = useTrackedFormik(
    {
      initialValues: { passcode: "" },
      validationSchema,
      onSubmit: (values) => {
        submitCustomChallenge(values.passcode);
      },
    },
    "passcode"
  );

  useEffect(() => {
    if (hasError) {
      resetUser();
    }
  }, [hasError, resetUser]);

  useEffect(() => {
    trackPageView({
      href: `${window.location.href}passcode`,
    });
  }, [trackPageView]);

  return (
    <>
      <MainLayout>
        <form onSubmit={formik.handleSubmit}>
          <Box mb={8}>
            <Typography variant="h4" component="h1" align="center" gutterBottom>
              {t("passcode.title")}
            </Typography>
            <Typography paragraph variant="body1" align="center">
              {t("passcode.intro")}
            </Typography>
          </Box>

          <Box mb={8}>
            <FormInput
              error={formik.errors.passcode}
              name="passcode"
              label={t("passcode.label")}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              touched={formik.touched.passcode}
              value={formik.values.passcode}
            />
          </Box>
          <Box mb={8}>
            <Grid container spacing={2}>
              <Hidden xsDown>
                <Grid item xs={12} md={6} />
              </Hidden>

              <Grid item xs={12} md={6}>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  size="large"
                  type="submit"
                >
                  {t("passcode.button")}
                </Button>
              </Grid>
            </Grid>
          </Box>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography variant="body1">{t("passcode.helpTitle")}</Typography>
            </AccordionSummary>
            <AccordionDetails style={{ flexDirection: "column" }}>
              <Typography variant="body1" component="span" paragraph>
                <Markdown>
                  {t("passcode.helpText", {
                    studyEmail: CONTENT.STUDY_EMAIL,
                    studyTelephone: CONTENT.STUDY_TEL,
                  })}
                </Markdown>
              </Typography>
            </AccordionDetails>
          </Accordion>
        </form>
      </MainLayout>
      <Loader isVisible={isBusy} label={t("verifying")} />
    </>
  );
}

export default PasscodeScene;
