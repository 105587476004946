export const ENVIRONMENT = process.env.REACT_APP_MAKE_ENV;
export const FULL_VERSION = process.env.REACT_APP_VERSION;
export const VERSION =
  process.env.REACT_APP_TAG || process.env.REACT_APP_COMMIT;

const ENVIRONMENT_CONFIG = {
  ENVIRONMENT,
  VERSION,
  FULL_VERSION,
};

export default ENVIRONMENT_CONFIG;
