import { RootState } from "../../store";
import { StoreStatuses, LanguageCode, Gender } from "../types/common.types";
import {
  GetMyProfileResponse,
  GetConsentFormLinkResponse,
  ParticipantProfile,
  UpdateMyProfileResponse,
  UpdateHealthDataOptInResponse,
  UpdateNotifyNewProductsResponse,
  UpdateAcceptLegalNoticesResponse,
  WithdrawParticipantResponse,
  GetUsernameResponse,
} from "../types/participant.types";
import {
  GetVisitsResponse,
  Visits,
  RegType,
  EhrStatus,
  CheckEhrStatusResponse,
} from "../types/health.types";
import {
  MOCK_HOSPITAL_NUMBER,
  MOCK_FIRST_NAME,
  POSTCODE,
  PHONE,
  EMAIL,
  MOCK_DOB_VALID,
  MOBILE,
  MOCK_LAST_NAME,
  ADDRESS,
  TERMS_VERSION,
  PRIVACY_VERSION,
  CONSENT_LINK,
} from "./strings.testing";
import thunk from "redux-thunk";
import configureStore from "redux-mock-store";

export const MOCK_INITIAL_STATE: RootState = {
  health: {
    status: StoreStatuses.IDLE,
    error: null,
    visits: [],
    ehrStatus: {
      dataHasArrived: false,
    },
  },
  participant: {
    status: StoreStatuses.IDLE,
    error: null,
    profile: null,
    consentLink: null,
    consentStatus: StoreStatuses.IDLE,
    consentError: null,
    withdrawalValues: null,
    withdrawalError: null,
    withdrawalStatus: StoreStatuses.IDLE,
    withdrawalResult: null,
    usernameLookup: null,
  },
};

export const MOCK_PROFILE: ParticipantProfile = {
  sk2: MOCK_HOSPITAL_NUMBER,
  postcode: POSTCODE,
  firstName: MOCK_FIRST_NAME,
  dob: MOCK_DOB_VALID,
  address: ADDRESS,
  landline: PHONE,
  email: EMAIL,
  mobile: MOBILE,
  healthDataOptIn: true,
  languagePreference: LanguageCode.EN,
  notifyNewProducts: true,
  notifyNewStudies: true,
  privacyAccepted: PRIVACY_VERSION,
  surname: MOCK_LAST_NAME,
  sex: Gender.FEMALE,
  termsAccepted: TERMS_VERSION,
  PISVersion: "v5.2",
};

export const MOCK_VISITS: Visits[] = [
  {
    visitLocation: "Renal",
    startDate: "2022-09-02",
    endDate: "2022-09-02",
    department: "Renal",
    registrationType: RegType.IN,
    PK: "1",
  },
  {
    visitLocation: "Diabetes Clinic",
    startDate: "2022-09-03",
    endDate: "2022-09-03",
    department: "Renal",
    registrationType: RegType.OUT,
    PK: "2",
  },
  {
    visitLocation: "Cardiovascular",
    startDate: "2022-07-10",
    endDate: "2022-07-12",
    department: "Renal",
    registrationType: RegType.ER,
    PK: "3",
  },
];

export const MOCK_EHR_STATUS: EhrStatus = {
  dataHasArrived: true,
  lastProcessedTime: "2023-02-17T15:25:02Z",
  exportedDate: "2023-02-16T15:25:02Z",
};

export const MOCK_PROFILE_RESPONSE: GetMyProfileResponse = {
  data: {
    getMyProfile: MOCK_PROFILE,
  },
};

export const MOCK_VISITS_RESPONSE: GetVisitsResponse = {
  data: {
    getEhrVisits: MOCK_VISITS,
  },
};

export const MOCK_EHR_STATUS_RESPONSE: CheckEhrStatusResponse = {
  data: {
    getEhrStatus: MOCK_EHR_STATUS,
  },
};

export const MOCK_CONSENT_LINK_RESPONSE: GetConsentFormLinkResponse = {
  data: {
    getConsentFormLink: CONSENT_LINK,
  },
};

export const MOCK_UPDATE_PROFILE_RESPONSE: UpdateMyProfileResponse = {
  data: {
    updateMyProfile: MOCK_PROFILE,
  },
};

export const MOCK_UPDATE_HEALTH_DATA_OPTIN_RESPONSE: UpdateHealthDataOptInResponse =
  {
    data: {
      updateHealthDataOptIn: MOCK_PROFILE,
    },
  };

export const MOCK_UPDATE_NOTIFY_NEW_PRODUCTS_RESPONSE: UpdateNotifyNewProductsResponse =
  {
    data: {
      updateNotifyNewProducts: MOCK_PROFILE,
    },
  };

export const MOCK_ACCEPT_LEGAL_NOTICES_RESPONSE: UpdateAcceptLegalNoticesResponse =
  {
    data: {
      acceptLegalNotices: MOCK_PROFILE,
    },
  };

export const MOCK_WITHDRAW_PARTICIPANT_RESPONSE: WithdrawParticipantResponse = {
  data: {
    withdrawParticipant: true,
  },
};

export const MOCK_CONFIRM_LOGIN_RESPONSE: GetUsernameResponse = {
  data: { confirmLogin: "DMSA473b5c9761" },
};

const middlewares = [thunk];
export const mockStore = configureStore(middlewares);
export default mockStore;
