import React from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Markdown from "react-markdown";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { ButtonRow, FormCheckBox, useTrackedFormik } from "@omnigenbiodata/ui";
import MainLayout from "../../../../../../layout/Main";
import ROUTES from "../../../../../../core/constants/routes.constant";
import { useAppSelector } from "../../../../../../store";
import { withdrawalValuesSelector } from "../../../../../../store/participant/selectors";

function WithdrawalConfirm1Scene() {
  const values = useAppSelector(withdrawalValuesSelector);
  const navigate = useNavigate();
  const { t } = useTranslation("portal");

  const validationSchema = yup.object({
    confirm: yup.boolean().oneOf([true], t("withdrawal4.validation")),
  });

  const formik = useTrackedFormik(
    {
      initialValues: {
        confirm: false,
      },
      validationSchema,
      onSubmit: (values) => {
        navigate(ROUTES.portalWithdrawalConfirm2);
      },
    },
    "withdrawal-confirm-1"
  );

  return (
    <MainLayout>
      <form onSubmit={formik.handleSubmit}>
        <Box mb={8}>
          <Typography variant="h4" component="h1" align="center" gutterBottom>
            {t("withdrawal4.title")}
          </Typography>
          <Typography variant="h5" component="h2" align="center" gutterBottom>
            <strong>{t("withdrawal4.subtitle")}</strong>
          </Typography>
          <Typography variant="body1" align="center" paragraph>
            {t("withdrawal4.para1")}
          </Typography>
          <Typography variant="body1" align="center" paragraph>
            {t("withdrawal4.para2")}
          </Typography>

          {values && values?.withdrawalOption && (
            <Typography
              variant="body1"
              component="span"
              align="center"
              paragraph
            >
              <Markdown>
                {t(`withdrawal2.options.${values?.withdrawalOption}.label`)}
              </Markdown>
            </Typography>
          )}

          <FormCheckBox
            name="confirm"
            value={formik.values.confirm}
            error={formik.errors.confirm}
            touched={formik.touched.confirm}
            label={t("withdrawal4.confirm")}
            onChange={formik.handleChange}
          />
        </Box>
        <ButtonRow
          backLabel={t("withdrawal4.back")}
          forwardLabel={t("withdrawal4.button")}
          forwardColor="error"
          buttonSize="large"
        />
      </form>
    </MainLayout>
  );
}

export default WithdrawalConfirm1Scene;
