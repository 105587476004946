import { createAsyncThunk } from "@reduxjs/toolkit";
import healthApi from "../../core/api/health";
import {
  GetVisitsResponse,
  CheckEhrStatusResponse,
} from "../../core/types/health.types";

/* Get Hospital Visits */
export const getVisitsThunk = createAsyncThunk(
  "health/getVisits",
  async (value: undefined, { rejectWithValue }: any) => {
    const response: GetVisitsResponse | any = await healthApi.getVisits();
    if (response.data?.getEhrVisits) {
      return response.data.getEhrVisits;
    }
    if (response.errors) {
      return rejectWithValue(response.errors);
    }

    return null;
  }
);

/* Check if EHR data has arrived */
export const getEhrStatusThunk = createAsyncThunk(
  "health/getEhrStatus",
  async (value: undefined, { rejectWithValue }: any) => {
    const response: CheckEhrStatusResponse | any =
      await healthApi.getEhrStatus();
    if (response.data?.getEhrStatus) {
      return response.data.getEhrStatus;
    }
    if (response.errors) {
      return rejectWithValue(response.errors);
    }

    return null;
  }
);
