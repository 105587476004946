import { createSlice } from "@reduxjs/toolkit";
import { StoreStatuses } from "../../core/types/common.types";
import { getVisitsThunk, getEhrStatusThunk } from "./thunks";
import { Visits, EhrStatus } from "../../core/types/health.types";

export interface ParticipantState {
  error: any;
  visits: Visits[];
  status: StoreStatuses;
  ehrStatus: EhrStatus;
}

// Initial State
export const initialState: ParticipantState = {
  status: StoreStatuses.IDLE,
  error: null,
  visits: [],
  ehrStatus: {
    dataHasArrived: false,
  },
};

// Participant Slice
export const healthSlice = createSlice({
  name: "health",
  initialState,
  reducers: {
    reset: () => ({
      ...initialState,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(getVisitsThunk.pending.type, (state, action: any) => {
      state.status = StoreStatuses.BUSY;
    });
    builder.addCase(getVisitsThunk.fulfilled.type, (state, action: any) => {
      state.status = StoreStatuses.IDLE;
      state.visits = action.payload;
      state.error = null;
    });
    builder.addCase(getVisitsThunk.rejected.type, (state, action: any) => {
      state.status = StoreStatuses.ERROR;
      state.error = action.error;
      state.visits = [];
    });
    builder.addCase(getEhrStatusThunk.pending.type, (state, action: any) => {
      state.status = StoreStatuses.BUSY;
    });
    builder.addCase(getEhrStatusThunk.fulfilled.type, (state, action: any) => {
      state.status = StoreStatuses.IDLE;
      state.ehrStatus = action.payload;
      state.error = null;
    });
    builder.addCase(getEhrStatusThunk.rejected.type, (state, action: any) => {
      state.status = StoreStatuses.ERROR;
      state.error = action.error;
    });
  },
});

// Action creators are generated for each case reducer function
export const { reset } = healthSlice.actions;
export * from "./thunks";
export default healthSlice.reducer;
