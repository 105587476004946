import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()((theme) => ({
  splash: {
    opacity: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "top",
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: theme.zIndex.modal,
    transition: "opacity 700ms ease-in 3s",
    padding: "10% 30px 30px 30px",
    textAlign: "center",

    "@media (orientation: portrait)": {
      background:
        "url(/images/splash.png) bottom 0px right 50% no-repeat, url(/images/backdrop.jpg) bottom right no-repeat, #e8f1f8 ",

      backgroundSize: "auto 40%, auto 100%",
    },

    "@media (orientation: landscape)": {
      background:
        "url(/images/splash.png) bottom 0px right 50% no-repeat, url(/images/backdrop.jpg) bottom right no-repeat, #e8f1f8 ",

      backgroundSize: "auto 40%, 100% 70%",
    },
  },
  splashHidden: { opacity: 0 },
  splashShown: {
    opacity: 1,
  },
  name: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightBold,
  },
  personalContent: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
  },
}));
