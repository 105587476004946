import { createAsyncThunk } from "@reduxjs/toolkit";
import participantApi from "../../core/api/participant";
import {
  GetMyProfileResponse,
  GetConsentFormLinkResponse,
  AcceptLegalNoticesParams,
  UpdateMyProfileParams,
  UpdateMyProfileResponse,
  WithdrawParticipantParams,
  GetUsernameParams,
} from "../../core/types/participant.types";

/* Lookup username */
export const confirmLoginThunk = createAsyncThunk(
  "participant/ConfirmLogin",
  async (options: GetUsernameParams, { rejectWithValue }: any) => {
    const response: GetMyProfileResponse | any =
      await participantApi.confirmLogin(
        options.loginMethod,
        options.loginParameter
      );
    if (response.data.confirmLogin) {
      return response.data.confirmLogin;
    }
    return rejectWithValue(response.errors);
  }
);

/* Get My Profile */
export const getMyProfileThunk = createAsyncThunk(
  "participant/GetMyProfile",
  async (value: undefined, { rejectWithValue }: any) => {
    const response: GetMyProfileResponse | any =
      await participantApi.getMyProfile();
    if (response.data?.getMyProfile) {
      return response.data.getMyProfile;
    }
    if (response.errors) {
      return rejectWithValue(response.errors);
    }

    return null;
  }
);

/* Get Consent Link  */
export const getConsentFormLinkThunk = createAsyncThunk(
  "participant/GetConsentFormLink",
  async (value: undefined, { rejectWithValue }: any) => {
    const response: GetConsentFormLinkResponse | any =
      await participantApi.getConsentFormLink();

    if (response.data?.getConsentFormLink) {
      return response.data.getConsentFormLink;
    }
    return rejectWithValue(response.errors);
  }
);

/* Update My Profile */
export const updateMyProfileThunk = createAsyncThunk(
  "participant/UpdateMyProfile",
  async (value: UpdateMyProfileParams, { rejectWithValue }: any) => {
    const response: UpdateMyProfileResponse =
      await participantApi.updateMyProfile(value);

    if (response.data?.updateMyProfile) {
      return response.data.updateMyProfile;
    }

    return rejectWithValue(response.errors);
  }
);

/* Update Health Opt In */
export const updateHealthDataOptInThunk = createAsyncThunk(
  "participant/UpdateHealthDataOptIn",
  async (value: boolean, { rejectWithValue }: any) => {
    const response = await participantApi.updateHealthDataOptIn(value);

    if (response.data?.updateHealthDataOptIn) {
      return response.data.updateHealthDataOptIn;
    }

    return rejectWithValue(response.errors);
  }
);

/* Update Notify New Products */
export const updateNotifyNewProductsThunk = createAsyncThunk(
  "participant/UpdateNotifyNewProducts",
  async (value: boolean, { rejectWithValue }: any) => {
    const response = await participantApi.updateNotifyNewProducts(value);

    if (response.data?.updateNotifyNewProducts) {
      return response.data.updateNotifyNewProducts;
    }

    return rejectWithValue(response.errors);
  }
);

/* Accept Legal Notices */
export const acceptLegalNoticesThunk = createAsyncThunk(
  "participant/AcceptLegalNotices",
  async (value: AcceptLegalNoticesParams, { rejectWithValue }: any) => {
    const response = await participantApi.acceptLegalNotices(value);

    if (response.data.acceptLegalNotices) {
      return response.data.acceptLegalNotices;
    }

    return rejectWithValue(response.errors);
  }
);

/* Withdraw Participant */
export const withdrawParticipantThunk = createAsyncThunk(
  "participant/WithdrawParticipantThunk",
  async (value: WithdrawParticipantParams, { rejectWithValue }: any) => {
    const response = await participantApi.withdrawParticipant(value);

    if (response.data.withdrawParticipant) {
      return response.data.withdrawParticipant;
    }

    return rejectWithValue(response.errors);
  }
);
